/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.tenant-yww .jss-form-wrapper .c-form-wrapper {
  padding-top: 0;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper div.heading-1 {
  margin-bottom: 20px;
  font-family: "Amaranth", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 28px;
  font-size: 1.75rem;
  letter-spacing: 0;
  line-height: 1.29;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .jss-form-wrapper .c-form-wrapper div.heading-1 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .jss-form-wrapper .c-form-wrapper div.heading-1 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.2;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .jss-form-wrapper .c-form-wrapper div.heading-1 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 1.15;
  }
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element {
  margin: 0 0 30px 0;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element .error-msg {
  position: absolute;
  bottom: -22px;
  left: 0;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-salutation {
  width: 60%;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-salutation {
    width: 27.5%;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-left, .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-right {
    width: 49%;
    margin-left: 0;
    margin-right: 1%;
    display: inline-block;
    vertical-align: middle;
  }
  .lang-ar .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-left, .lang-ar .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-right {
    margin-left: 1%;
    margin-right: 0;
  }
  .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-right {
    margin-left: 1%;
    margin-right: 0;
  }
  .lang-ar .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.form-element-right {
    margin-left: 0;
    margin-right: 1%;
  }
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element select {
  font-size: 16px;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.input-type-select-date .form-label {
  top: 15px;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.input-type-select-date .selected-option {
  padding-left: 18px;
  padding-right: 0;
  padding-top: 14px;
  padding-bottom: 15px;
}
.lang-ar .tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.input-type-select-date .selected-option {
  padding-left: 0;
  padding-right: 18px;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.input-type-select-date.active .form-label {
  top: 4px;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.input-type-select-date.active .selected-option {
  padding-top: 21px;
  padding-bottom: 10px;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.select-box .form-label {
  z-index: 1;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .form-element.select-box > div:after {
  z-index: 0;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .text-center .btn-primary {
  margin-top: 12px;
}
.tenant-yww .jss-form-wrapper .c-form-wrapper .text-center .btn-primary button {
  margin-top: 0px;
}
.tenant-yww .jss-form-wrapper.B2C-bookDate .disabled:not(.product-selection) {
  opacity: 0.3;
  pointer-events: none;
}
.tenant-yww .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .cart-summary-container {
  padding: 0;
}
.tenant-yww .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor {
  font-family: "Amaranth", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 30px;
  letter-spacing: 0;
  /* Medium devices (desktops, 1024px and up) */
  color: #404040;
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.tenant-yww .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .form-wrapper-buttons-anchor:after {
  left: 0;
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 2px solid #3e8bcf;
  top: 110%;
}
.tenant-yww .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .header-bottom {
  background-color: transparent;
  padding: 0;
}
.tenant-yww .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .header-bottom .description {
  color: #404040;
}
.lang-ar .tenant-yww .jss-form-wrapper.B2C-bookDate .contact-us-form .c-my-cart-b2c-container .checkout-button .login-signup .header-bottom .c-ticket-tooltip .header-bottom-profile .ticket-tooltip-body {
  left: 32px;
  right: auto;
}
.tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar {
  background-color: #f6f6f6;
  /* Medium devices (desktops, 1024px and up) */
}
.tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__day--selected, .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__day--keyboard-selected {
  background-color: #0073cd;
  color: #ffffff;
}
.tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__navigation--previous, .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__navigation--next {
  margin-top: 0;
}
.tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__navigation--previous:before, .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__navigation--next:before {
  left: 20px;
}
.lang-ar .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__navigation--previous:before, .lang-ar .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .react-datepicker__navigation--next:before {
  right: 20px;
  left: auto;
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar {
    top: 58px;
  }
}
.tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.7px;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-size: 13px;
  font-size: 0.8125rem;
  margin: 0 22px 20px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media (min-width: 768px) {
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer .calendar-labels .calendar-label {
    margin: 5px 0;
    width: 50%;
  }
}
@media only screen and (min-width: 1024px) {
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer {
    font-size: 13px;
    font-size: 0.8125rem;
    margin: 0 32px 20px;
  }
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer .calendar-labels .calendar-label {
    margin: 0;
    width: 25%;
  }
}
@media only screen and (min-width: 1366px) {
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer {
    font-size: 13px;
    font-size: 0.8125rem;
    margin: 0 44px 20px;
  }
  .tenant-yww .jss-form-wrapper .booking-calendar-wrapper .book-ticket-calendar .calendar-footer .calendar-labels .calendar-label {
    margin: 0;
    width: 25%;
  }
}

.lang-ar .jss-form-wrapper fieldset {
  text-align: right;
}
.lang-ar .jss-form-wrapper .c-form .form-element .error-msg {
  right: 0;
}
.lang-ar .jss-form-wrapper .c-form .c-captcha--botdetect {
  text-align: right;
}
.lang-ar .jss-form-wrapper .c-form .c-captcha--botdetect-image {
  margin-right: 0;
  margin-left: 10px;
}
.lang-ar .jss-form-wrapper .dob-calendar-wrapper .book-ticket-calendar .calendar-footer {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .jss-form-wrapper .dob-calendar-wrapper .book-ticket-calendar .calendar-footer {
    margin: 0 25px 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .jss-form-wrapper .dob-calendar-wrapper .book-ticket-calendar .calendar-footer {
    margin: 0 32px 20px;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .jss-form-wrapper .dob-calendar-wrapper .book-ticket-calendar .calendar-footer {
    margin: 0 44px 20px;
  }
}
.lang-ar .jss-form-wrapper .c-form-wrapper .form-element select {
  font-size: 13px;
  font-size: 0.8125rem;
}

.corporate-enrollment .tenant-yww .jss-form-wrapper .c-form-wrapper div.heading-1 {
  margin-top: 3rem;
}